import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: () => import('@/views/HomeView.vue')
  },
  {
    path: '/ride',
    name: 'ride',
    component: () => import('@/views/RideView.vue')
  },
  {
    path: '/more',
    name: 'LearnMore',
    component: () => import('@/views/LearnMore.vue')
  },
  {
    path: '/price',
    name: 'PriceView',
    component: () => import('@/views/PriceView.vue')
  },
  {
    path: '/vision',
    name: 'VisionView',
    component: () => import('@/views/VisionView.vue')
  },
  {
    path: '/help',
    name: 'HelpView',
    component: () => import('@/views/HelpView.vue')
  },
  {
    path: '/blog',
    name: 'BlogView',
    component: () => import('@/views/BlogView.vue')
  },
  {
    path: '/news1',
    name: 'NewsOne',
    component: () => import('@/views/NewsOne.vue')
  },
  {
    path: '/news2',
    name: 'NewsTwo',
    component: () => import('@/views/NewsTwo.vue')
  },
  {
    path: '/parking',
    component: () => import('@/views/ParkingView.vue')
  },
  // {
  //   path: '/privacy',
  //   component: () => import('@/views/PrivacyView.vue')
  // },
  // {
  //   path: '/terms',
  //   component: () => import('@/views/TermsView.vue')
  // },
  {
    path: '/sustainability',
    component: () => import('@/views/Sustainability.vue')
  },
  {
    path: '/mobility',
    component: () => import('@/views/MobilityView.vue')
  },
  {
    path: '/cities',
    component: () => import('@/views/CitiesView.vue')
  },
  {
    path: '/safe',
    component: () => import('@/views/SafeView.vue')
  },
  {
    path: '/about',
    component: () => import('@/views/AboutView.vue')
  },
  {
    path: '/careers',
    component: () => import('@/views/CareersView.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    // return 期望滚动到哪个的位置
    if (savedPosition) {
      return savedPosition
    } else {
      // vue2.0  x  y  控制
      // vue3.0  left  top 控制
      return {x: 0, y: 0}
    }
  }
})

export default router
