export default {
    'HeaderNav1': 'Ride',
    'HeaderNav2': 'Vision',
    'HeaderNav3': 'Help',
    'HeaderNav4': 'Blog',
    'HeaderText1': 'Get the app',
    'HeaderText3': 'Back to Home Page',
    'HeaderText2': 'Get the Voi app',
    'LangChoose': 'Choose Country',
    'SectionOneTitle': 'Let’s reimagine our cities!',
    'SectionOneText': 'Get the Voi app',
    'SectionOneTip': 'Your browser does not support the video tag.',
    'SectionTwoTitle': 'The smarter way to get around town',
    'SectionTwoText': 'Rent an e-scooter or e-bike from Voi with just a tap on your phone, and get anywhere in the city within minutes. The perfect way to explore a new place, or simply experience your own hometown from a different perspective.',
    'SectionTwoBtn': 'How it works',
    'SectionThreeTitle1': 'Together towards',
    'SectionThreeTitle2': 'a better tomorrow',
    'SectionThreeItemOneTitle1': 'We educate',
    'SectionThreeItemOneTitle2': 'our users',
    'SectionThreeItemOneText': ' Knowledge is key to ensure a safe and sustainable traffic environment.',
    'SectionThreeItemTwoTitle1': 'We collaborate ',
    'SectionThreeItemTwoTitle2': 'with cities',
    'SectionThreeItemTwoText': ' Because real, lasting change only happens when we work together.',
    'SectionThreeItemThreeTitle1': 'We integrate ',
    'SectionThreeItemThreeTitle2': 'with public transport',
    'SectionThreeItemThreeText': 'Our service works in tandem with the broader transit ecosystem.',
    'SectionFourTitle': 'Cities made for living',
    'SectionFourText': 'At Voi, we’re putting people back at the centre of urban transformation. Our vision is a future in which city dwellers live and move in a safe and healthy environment with less pollution, less noise and less stress.',
    'SectionFourBtn': ' Learn about our vision ',
    'SectionFiveTitle': 'Latest news',
    'SectionFiveItemOneTitle': 'Voi launches its Safety Report with a core message to cities: Let’s work together to get it right',
    'SectionFiveItemTwoTitle': 'A Voi e-scooter guide to parking',
    'SectionFiveItemThreeTitle': 'Voi’s climate targets have been approved by the Science Based Targets initiative',
    'FooterItemOneTitle': 'Riders',
    'FooterItemOneText1': 'How it works',
    'FooterItemOneText2': 'Safe riding',
    'FooterItemOneText3': 'Pricing & passes',
    'FooterItemOneText4': 'How to park',
    'FooterItemOneText5': 'Help centre',
    'FooterItemTwoTitle': 'Cities & partners',
    'FooterItemTwoText1': 'City offering',
    'FooterItemTwoText2': 'Our approach',
    'FooterItemTwoText3': 'Traffic safety',
    'FooterItemTwoText4': 'Parking solutions',
    'FooterItemTwoText5': 'Mobility partners',
    'FooterItemThreeTitle': 'Company',
    'FooterItemThreeText1': 'About us',
    'FooterItemThreeText2': 'Locations',
    'FooterItemThreeText3': 'Sustainability',
    'FooterItemThreeText4': 'Careers',
    'FooterItemThreeText5': 'Press',
    'FooterItemFourTitle': 'Resources',
    'FooterItemFourText1': 'Terms of use',
    'FooterItemFourText2': 'Privacy policy',
    'FooterItemFourText3': 'Cookies',
    'FooterItemFourText4': 'Publications',
    'FooterItemFourText5': 'Modern Slavery Statement',
    'FooterDesign': 'Designed by VOI in Sweden',
    'chooseLanguage': 'Choose Country',
    'rideFirstModuleTitle': 'How it works',
    'rideFirstModuleText': 'Moving around town has never been easier or more convenient. Learn how to get rolling in a few simple steps and reach your destination within minutes.',
    'rideSecondModuleTitle': 'Get going in no time:',
    'rideSecondModuleText1-1': 'Download the app',
    'rideSecondModuleText1-2': 'for free',
    'rideSecondModuleText1-3': 'and register an account.',
    'rideSecondModuleText2-1': 'Find an e-scooter or e-bike',
    'rideSecondModuleText2-2': 'nearby using the in-app map.',
    'rideSecondModuleText3-1': 'Unlock it by scanning the',
    'rideSecondModuleText3-2': 'QR code on the handlebar.',
    'rideSecondModuleText4-1': 'Hop on and get anywhere in',
    'rideSecondModuleText4-2': 'the city within minutes.',
    'rideThreeModuleTitle': 'E-scooter or e-bike?',
    'rideThreeModuleText1': 'Our e-scooters are excellent for when you need to quickly get somewhere within a somewhat shorter distance, while our e-bikes are ideal for longer routes.',
    'rideThreeModuleText2': 'If you’re new to e-scooters – activate reduced-speed mode in the app. This caps the max speed of the scooter, allowing you to start off slow while learning to operate the vehicle.',
    'rideFourModuleTitle': 'Who can ride an electric scooter?',
    'rideFourModuleText1': 'You must be at least 18 years old and hold a valid driving licence to rent an electric scooter in the UK.',
    'rideFourModuleText2': 'Thus, upon registering, you’re required to verify your identity by uploading a photo of your driving licence along with a selfie.',
    'rideFiveModuleTitle': 'Riding zones',
    'rideFiveModuleText': 'Our vehicles respond to pre-set rules depending on their location. All cities where Voi is available are classified into virtual zones with different regulations.Be sure to familiarise yourself with each one before you set off.',
    'rideSixModuleTitle1': 'PARKING ZONE +',
    'rideSixModuleText1': 'This is a great place to park. So good, in fact, that we’ll grant you a discount as a way to say thanks for parking here.',
    'rideSixModuleTitle2': 'PARKING ZONE',
    'rideSixModuleText2': 'In some cities, parking is permitted only in designated parking areas. Use the map to find a parking zone near your destination before you set off.',
    'rideSixModuleTitle3': 'NO-PARKING ZONE',
    'rideSixModuleText3': 'Some places are simply inappropriate for parking. That’s why we have no-parking zones where you won’t be able to end your ride.',
    'rideSevenModuleTitle1': 'LOW-SPEED ZONE',
    'rideSevenModuleText1': 'Low-speed zones are areas usually crowded with either cars or pedestrians. Therefore, the vehicle will automatically slow down a bit here.',
    'rideSevenModuleTitle2': 'NO-RIDING ZONE',
    'rideSevenModuleText2': 'As the name suggests, this is a no-go area for all Voi vehicles. Here, you won’t be able to neither ride nor park your e-scooter or e-bike.',
    'rideSevenModuleTitle3': 'OUTSIDE THE OPERATIONAL ZONE',
    'rideSevenModuleText3': 'Voi vehicles belong within the operational zone. If you leave with one, the vehicle will come to a full stop and you won’t be able to end your ride.',
    'rideEightModuleTitle': 'Parking – what applies?',
    'rideEightModuleText1': 'As with the different riding zones, parking regulations vary between cities – always check the app to find out what applies in your area.',
    'rideEightModuleText2': 'Proper parking is not just about aesthetics; above all, it’s a matter of safety and accessibility.',
    'rideEightModuleBtn': 'Learn more about parking',
    'RideNineModuleTitle': 'Pricing and passes',
    'RideNineModuleText': 'Ride more for less with a monthly subscription, get a day pass or simply pay as you go. Prices vary depending on the city – check the Voi app for the exact prices that apply in your area.',
    'RideNineModuleBtn': 'See prices',
    'RideTenModuleTitle': 'Still have questions?',
    'RideTenModuleText': 'Find the answers to our most common questions in our help centre, where you’ll also find a bunch of tips, tricks and useful info on our service.',
    'RideTenModuleBtn': 'Visit help centre',
    'RideTripModuleText1': 'Safe riding',
    'RideTripModuleText2': 'Parking',
    'RideTripModuleText3': 'Vehicles',
    'moreFirstModuleTitle': 'Parking made simple',
    'moreFirstModuleText': 'Here it is – the guide you’ve been looking for.',
    'moreSecondModuleText': 'At Voi, we believe in safe and inclusive cities made for living. Therefore, we urge you to always park properly and help keep the pavements an accessible space for all.',
    'moreThreeModuleTitle1': 'First, the basics:',
    'moreThreeModuleTitle2': 'Know your local rules',
    'moreThreeModuleText': 'Keep yourself informed of the local rules and regulations in regards to parking – and abide by them. Read on to learn more.',
    'moreFourModuleTitle': 'Show consideration',
    'moreFourModuleText': 'Be sure not to block any access points or crosswalks, or in any other way obstruct the path of pedestrians or other vehicles.',
    'moreFiveModuleTitle': 'Different cities – different regulations',
    'moreFiveModuleText': 'It’s usually one of the following that applies:',
    'moreSixModuleTitle': 'Mandatory parking areas',
    'moreSixModuleText': 'In some cities, parking is permitted only in racks and other spaces designated for e-scooter and e-bike parking. If you’re in such a city, these mandatory parking spaces are marked in blue (‘parking zones’) in the app. Before you set off, find a parking zone near your destination using the in-app map.',
    'moreSevenModuleTitle': 'Free-float parking',
    'moreSevenModuleText': 'In other cities, parking is allowed throughout most of the operational area, except in the so-called no-parking and no-riding zones, which are marked on the in-app map. Here, you need to park the vehicle either parallel and close to a wall, in a rack or within a designated parking space.',
    'moreEightModuleTitle': 'Combined parking rules',
    'moreEightModuleText1': 'In still other cities, there’s a combination of regulations that applies. This usually means that the city centre has mandatory parking areas, while parking outside the city centre is permitted according to the free-float model.',
    'moreEightModuleText2': 'Check the app for more information about the specific parking rules and regulations that apply in your area.',
    'MoreNineModuleTitle': 'The importance of proper parking',
    'MoreNineModuleText1': 'Proper parking is not just about aesthetics; above all, ',
    'MoreNineModuleText2': 'it’s a matter of safety and accessibility.',
    'MoreNineModuleText3': 'Learn more about how we work to improve the safety in our cities through our best-in-class ',
    'MoreNineModuleText4': 'parking solutions',
    'MoreNineModuleText5': 'Failing to comply with local parking rules is a violation of our ',
    'MoreNineModuleText6': 'user agreement',
    'MoreNineModuleText7': 'and may result in fines.',
    'MoreTenModuleTitle': 'Seen a misplaced vehicle?',
    'MoreTenModuleText1': 'If you see one of our e-scooters or e-bikes parked in an inappropriate location, please ',
    'MoreTenModuleText2': 'report it to our operations team',
    'MoreTenModuleText3': ' so they can take swift action.',
    'MoreTripModuleText1': 'Safe riding',
    'MoreTripModuleText2': 'How it works',
    'MoreTripModuleText3': 'Sustainability',
    'PriceFirstModuleTitle': 'Pricing and passes',
    'PriceFirstModuleText': 'Simple pricing, endless possibilities. Choose what suits you best!',
    'PriceSecondModuleTitle': 'Choose your price plan',
    'PriceThreeModuleTitle': 'For occasional rides',
    'PriceThreeModuleText1': 'The pay-as-you-go pricing option is great for occasional riders travelling shorter distances.',
    'PriceThreeModuleText2': 'You pay around £1 to unlock the vehicle, then between £0.14 to £0.20 per minute.',
    'PriceThreeModuleText3': 'Price example: On average, a 10-minute ride using pay-as-you-go in the UK costs about £2.5 (before any discounts are applied).',
    'PriceThreeModuleBtn': 'Try pay-as-you-go ',
    'PriceFourModuleTitle': 'A day on two wheels',
    'PriceFourModuleText1': 'With a Voi day pass, you don’t pay the usual unlocking fee when starting a ride, and you get about 100–120 riding minutes to use during 24 hours.',
    'PriceFourModuleText2': 'A day pass usually costs between £7 and £10 in the UK. It’s perfect for exploring new or known cities.',
    'PriceFourModuleText3': 'Price example: If you take around 5 rides using a day pass, the average cost is about £1.5 per ride.',
    'PriceFourModuleBtn': 'Get a day pass',
    'PriceFiveModuleTitle': 'For frequent riding',
    'PriceFiveModuleText1': 'Go green and save money with a monthly Voi Pass subscription. With this pass, you won’t pay any unlocking fees and you’ll have an optional amount of riding minutes included each month.',
    'PriceFiveModuleText2': 'Monthly subscription options and costs differ by location. For example, a subscription with 300 minutes included typically costs around £35 per month in the UK. That gives you around 30 rides of 10 minutes each, or 20 rides of 15 minutes each.',
    'PriceFiveModuleText3': 'Price example: On average, the cost per ride using a monthly pass in the UK ranges from £0.7 to £1.',
    'PriceFiveModuleBtn': 'Get a month pass',
    'PriceSixModuleTitle': 'Always free unlock with Voi Pass',
    'PriceSixModuleText': 'If you use up all the minutes included in your daily or monthly pass, don’t worry! You can still unlock all your rides for free, and you’ll only be charged the standard minute fee until your pass expires or renews automatically. Go to the app to check the exact pricing at your location and to read the full terms.',
    'PriceSixModuleBtn': 'Open the Voi app',
    'PriceSevenModuleTitle': 'Payment options',
    'PriceSevenModuleText1': 'We accept several popular payment methods and some local favourites too.',
    'PriceSevenModuleText2': 'Download',
    'PriceSevenModuleText3': 'the app',
    'PriceSevenModuleText4': 'hop on and go. ',
    'PriceEightModuleTitle': 'Ride safely and unlock exclusive deals',
    'PriceEightModuleText': 'Complete our online traffic school, park in designated incentivised zones, or snap a helmet-wearing selfie to earn rewards.',
    'PriceEightModuleBtn': 'Learn safe riding',
    'PriceNineModuleText1': 'The prices, products, features, and promotions mentioned herein are subject to change and may vary depending on factors such as location, time, vehicle type, and other circumstances. The information provided is for general informational purposes only and is not intended to be a comprehensive representation of the specific pricing, promotions, payment methods or products available and applicable in your city, region, or country.',
    'PriceNineModuleText2': 'Prices mentioned on this page are approximate and may be subject to additional fees and other charges depending on local regulations and requirements. It is your responsibility to familiarise yourself with any such additional costs before using the service.',
    'PriceNineModuleText3': 'To ensure you have the most accurate and up-to-date pricing information, please refer to the Voi app for the specific prices and promotions available in your area.',
    'PriceTripModuleTitle1': 'How it works',
    'PriceTripModuleTitle2': 'SAFE RIDING',
    'PriceTripModuleTitle3': 'Parking',
    'HelpHeaderTitle': 'How can we help?',
    'HelpHeaderText': 'Search help articles',
    'HelpCategoryText1': 'Getting Started',
    'HelpCategoryText2': 'Account and Payments',
    'HelpCategoryText3': 'Help with your ride',
    'HelpCategoryText4': 'Parking Guidelines',
    'HelpCategoryText5': 'Safety and T&C\'s',
    'HelpCategoryText6': 'Long Term Rentals (UK)',
    'BlogHeaderTitle': 'Voi Blog',
    'BlogHeaderText': 'News, updates and insights from the micromobility movement.',
    'BlogNewsTitle1': 'Voi Joins Forces with European Mobility Leaders in Future Mobility Taskforce',
    'BlogNewsOneText1': 'As a key player in the European mobility landscape, Voi has been driving sustainable transportation solutions and contributing to the evolution of shared mobility. Embracing the spirit of collaboration and innovation, we are excited to announce our participation in the EU Future Mobility Taskforce.',
    'BlogNewsOneText2': 'Launched in January 2024 in collaboration with EU Transport Commissioner Adina Vălean, this taskforce brings together 18 influential companies and startups across shared mobility, EV charging, maglev, rail, coach, cargo, and air mobility sectors. Our collective aim is to provide valuable insights to policymakers, fostering sustainable, affordable, and connected mobility solutions within existing transport networks.',
    'BlogNewsOneText3': 'Voi, alongside esteemed partners like Bolt, Cabify, Flix, Volocopter and others, is committed to shaping the future of transportation. By developing a comprehensive set of recommendations, we seek to contribute to unlocking economic, social, and environmental benefits inherent in the mobility sector. These recommendations will be presented to Commissioner Vălean in the spring.',
    'BlogNewsOneText4': 'Douglas Stark, COO at Voi and founding member of the task force: “We are excited about the potential impact and look forward to collaborating with fellow industry leaders on this transformative journey.”',
    'BlogNewsTitle2': 'Re-tendering UK e-scooter trials: reflections from Voi on how to get it right',
    'BlogNewsTwoText1': 'UK e-scooter trials have now been running for over three years. In November 2023, instead of announcing long-trailed legislation to firmly embed micromobility into our transport system, the government once again extended them until May 2026. Some councils and regional authorities – who manage their local e-scooter trials – are reviewing current contracts, determining whether to extend terms with existing providers or issue tenders for new contracts.',
    'BlogNewsTwoText2': 'A journalist reporting on the transition to a new micromobility operator in the West of England recently stated, “if it ain’t broke, why fix it”? We have been tagged in numerous social media posts asking us to go back to Bristol, where until a change in operators in October, we built and ran one of the most successful e-scooter schemes in Europe. On social media and in news reports our ex-riders were complaining about a lack of hop-on hop-off scooters on the streets, no long-term rental scooters, a lack of parking availability and also asking questions about the e-bikes and cargo bikes, which were promised in the new contract but by the beginning of December had still failed to materialise.',
    'BlogNewsTwoText3': 'This led us to reflect on how local authorities and micromobility operators should work together to ensure that any change of operator is as seamless as possible, and that crucially, disruption in service to riders is minimised. The success of the trials means that people are relying on them to get to work, to education, for business appointments, to go to the shops and for leisure. If there is a break in service – with either no scooters for several months or far fewer scooters – then these riders are forced to find another source of transport. Sadly, we’ve seen reports on social media that some opt to start using cars again.',
    'BlogNewsTwoText4': 'Drawing on our experience of the transitions we’ve been involved in this year in the UK, including starting operations in London, Cheltenham and Gloucester. These are our thoughts about best practice.',
    'BlogNewsTwoText5': 'Good design of contracts is essential',
    'BlogNewsTwoText6': 'Councils should not see e-scooters as short-term cash cows and remember that we are the only form of sustainable  transport not to receive public subsidy or capital investment.  We invest in warehouses, people and hardware; it’s a capital intensive industry, operating on low margins. To be financially sustainable we need contracts to be financially sustainable; this means blind auction revenue share bids should be avoided. We can’t run schemes at a loss and demanding  revenue shares put services at risk. This approach could lead to operators going out of business, councils having to re-procure and citizens losing a transport option they’ve come to rely on.',
    'BlogNewsTwoText7': 'There must be verification that operators can deliver on promises',
    'BlogNewsTwoText8': 'We are always happy for local authorities to visit one of our warehouses to see how they’re run, check our robust health and safety and watch our mechanics at work, as well as to try out our e-scooters and e-bikes. We can demonstrate and explain what it takes to run a financially sustainable and responsible service. That way they will see that we’re able to deliver on our promises and ensure corners are not cut. ',
    'BlogNewsTwoText9': 'Collaboration is key',
    'BlogNewsTwoText10': 'It is in everyone’s interests to make sure contracts work for everyone – riders, councils, operators, and the population at large. The best way to make this happen is for operators to work closely with local authorities who know their communities inside out and to listen to feedback from riders and other road users or pedestrians who share roads and paths with our vehicles.',
    'BlogNewsTwoText11': '*Matthew Pencharz is Head of Public Policy for Voi UK, Ireland and France. He’s happy to engage in further conversation with local authorities seeking to tender for micromobility services in this region.',
    'BlogNewsReadMore': 'Read More',
    'BlogNewsReadShare': 'SHARE',
    'VisionFirstTitle': 'More than half the world’s population now live in cities',
    'VisionFirstText1': 'By 2050, this will reach two-thirds. As more people move from rural areas to cities, there’ll be more cars on the roads, more traffic congestion hotspots near homes and workplaces, and less green space.',
    'VisionFirstText2': 'Unless we are bold enough to challenge the status quo and do something about it.',
    'VisionSecondText1': 'Our vision:',
    'VisionSecondText2': 'Cities made for living, free from noise and pollution.',
    'VisionThreeText1': 'At Voi, we believe in a future where roads in cities are recaptured by people. We want people to be put back at the centre of urban transformation, aiming for a tomorrow in which people live and move in a safe and healthy environment with less pollution, less noise, and more space.',
    'VisionThreeText2': 'The future we see is a sustainable one where circularity is the norm and shared mobility the way to go. Where individuals, businesses and governments alike take responsibility and strive to reduce their climate impact, help protect our planet and contribute to more liveable cities for all.',
    'VisionThreeBtn': 'Vision Statement',
    'VisionFourText': 'Envision the future of your city',
    'VisionFiveTitle': 'Stockholm - Sveavägen',
    'VisionFiveText1': 'Sveavägen is a busy main road in central Stockholm with up to five lanes of traffic. Our proposal, developed with JAJA Architects, is to create a green spine on Sveavägen, rather than a polluting road.',
    'VisionFiveText2': 'The green spine would prioritise walking, biking, micromobility, public transport and delivery cargo bikes. In addition, it would provide space for people to enjoy a less frantic, and more vibrant city centre.',
    'VisionSixTitle': 'Marseille - Rue Paradis',
    'VisionSixText1': 'Marseille has mainly car-oriented transport corridors between the city centre and its suburbs, which hinders the promotion of micromobility.',
    'VisionSixText2': 'To address this, the proposal for Rue Paradis suggests cutting down to a one-direction car lane and creating a mobility hub to connect micromobility and buses. A cycle lane has also been included, separated from traffic by a "flex zone," which is created by removing 50% of the existing parking spots.',
    'VisionSevenTitle': '15-minute cities',
    'VisionSevenText1': 'A new paradigm for urban development that emphasizes access to daily necessities within a 15-minute radius, achieved through walking, biking, or public transit. Voi supports this idea for urban development and believes shared micromobility plays an important role in strengthening public transportation networks.',
    'VisionEightTitle': 'Our mission：',
    'VisionEightText1': 'Safe, sustainable and reliable micromobility for everyone.',
    'VisionNineTitle': 'To achieve our vision, we offer different modes of light mobility vehicles that are safe, affordable, and easy to use – allowing people to move around the city on their terms. By making sustainable transportation options more widely available, we believe that we can improve quality of life in cities around the world, while also contributing to the fight against climate change.',
    'VisionNineText1': 'For instance, combining a bus and a scooter can reduce hurdles and total travel time, making public transport a viable alternative to the car. This is particularly relevant in the context of commuting, whether it’s for work, to meet relatives, or to visit a gallery with friends.',
    'VisionTenTitle': 'Closer ties between micromobility and public transport benefit cities',
    'VisionTenText1': 'A joint initiative between Voi, Mobimeo and German rail operator S-Bahn Stuttgart has proven to both improve e-scooter parking and boost public transport ticket sales. This project is a promising step in the direction of mutually beneficial integration between micromobility and public transport providers.',
    'VisionTenBtn': 'Read the case study ',
    'VisionTextTitle': 'Integrating with other transportation services',
    'VisionTextText1': 'Voi offers an efficient way to extend the reach and productivity of existing public transport systems which would otherwise require large infrastructure investments.',
    'VisionTextText2': 'Our ready-to-use framework uses standard application programming interfaces (APIs) for shared mobility operators, such as GBFS & MDS, making our service easily integrated with public transportation, platforms for route planning and Mobility as a Service (MaaS) applications.',
    'VisionTextBtn': 'Read more',
    'VisionSchemeTitle': 'We\'re only at the start of our voyage',
    'VisionSchemeText1': 'Follow our blog for the latest news and insights from Voi Technology.',
    'VisionSchemeBtn': 'Read our blog',
    'AboutOneTitle': 'Reimagine your city',
    'AboutOneText': 'Cities made for living, free from noise and pollution.',
    'AboutTwoTitle': 'About us',
    'AboutTwoText1': 'Voi is one of the fastest-growing mobility companies in the world.',
    'AboutTwoText2': 'Born in Sweden, we drive innovation through our state-of-the-art hardware and software solutions, shaping the future of sustainable transportation and urban development.',
    'AboutTwoText3': 'Experience the convenience of getting from point A to point B with our shared electric vehicles, while reducing air and noise pollution and easing traffic congestion across Europe.',
    'AboutThreeTitle': 'For riders',
    'AboutThreeText1': 'Voi provides top-notch shared electric vehicles on demand through our Voi application, making urban travel more convenient.',
    'AboutThreeText2': "Simply use the app's map to find nearby electric scooters or bicycles, scan the QR code on the handlebar with your phone to unlock the vehicle, and then start exploring the city effortlessly and freely.",
    'AboutMoreBtn': 'Learn more',
    'AboutFourTitle': 'For towns & cities',
    'AboutFourText1': 'Voi collaborates with city officials and organizations to address urban pain points, provide customized solutions, and meet specific transportation needs and opportunities in your area.',
    'AboutFourText2': "Our approach makes Voi a trusted and reliable partner for cities, as evidenced by our position as the operator with the most scooters in European licensing and bidding projects.",
    'AboutFiveTitle': 'For transportation services',
    'AboutFiveText1': 'Voi offers an efficient way to extend the reach and productivity of existing public transport systems which would otherwise require large infrastructure investments.',
    'AboutFiveText2': 'Our ready-to-use framework uses standard application programming interfaces (APIs) for shared mobility operators, making our service easily integrated with public transportation, platforms for route planning and Mobility as a Service (MaaS) applications.',
    'AboutSixTitle': 'Our vision: Cities made for living, free from noise and pollution',
    'AboutSixText1': 'At Voi, we believe in a future where roads in cities are recaptured by people.',
    'AboutSixText2': 'We put people back at the centre of urban transformation, aiming for a tomorrow in which people live and move in a safe and healthy environment with less pollution, less noise, and more space.',
    'AboutSixText3': 'By making sustainable transportation options more widely available, we believe that we can improve quality of life in cities around the world, while also contributing to the fight against climate change.',
    'AboutSixBtn': 'Our Vision Statement',
    'AboutEightTitle': 'Our mission: Safe, sustainable and reliable micromobility for everyone',
    'AboutEightText1': 'To achieve our vision, we offer different modes of light mobility vehicles that are safe, affordable, and easy to use – allowing people to move around the city on their terms. ',
    'AboutEightText2': 'By reducing reliance on cars and improving access to sustainable transportation, we can help create better and safer cities for everyone.',
    'AboutEightBtn': 'How it works',
    'AboutNineTitle': 'Micromobility can help reduce carbon emissions in urban areas',
    'AboutNineText1': 'By offering alternative modes of transportation and enhancing access to public transportation, micromobility can help cities reach their climate goals. We take a comprehensive approach to minimise our environmental impact and continuously improve our sustainable practices.',
    'AboutTenTitle': 'Safety at the Heart of Voi\'s Swedish Heritage: Building on the Vision Zero Approach to Road Safety',
    'AboutTenText1': 'Safety has been at the forefront of our mission since the very beginning. Our vision is to create cities that prioritise the well-being of its citizens, and safety is an essential component of achieving that goal.',
    'About11Title': 'Our team is our biggest strength',
    'About11Text1': 'At Voi, we\'re leading the way for more sustainable transportation with a team that\'s as diverse as the communities we serve. ',
    'About11Text2': 'Whether you\'re maintaining our vehicles on the streets, expanding to new markets, or developing our app, you\'ll be part of a dynamic and passionate team shaping the cities of tomorrow.',
    'About11Text3': 'Check out our current job openings and apply today.',
    'About11Btn': 'Open positions',
    'About12Title': 'Voi is available in 100+ towns and cities all over Europe',
    'About12Country1': 'Austria',
    'About12Country1Town1': 'Vienna',
    'About12Country2': 'Belgium',
    'About12Country2Town1': 'Brussels',
    'About12Country3': 'Denmark',
    'About12Country3Town1': 'Aalborg',
    'About12Country3Town2': 'Aarhus',
    'About12Country3Town3': 'Odense',
    'About12Country4': 'Finland',
    'About12Country4Town1': 'Helsinki',
    'About12Country4Town2': 'Jyvaskyla',
    'About12Country4Town3': 'Tampere',
    'About12Country4Town4': 'Turku',
    'About12Country5': 'France',
    'About12Country5Town1': 'Marseille',
    'About12Country6': 'Germany',
    'About12Country6Town1': 'Aachen',
    'About12Country6Town2': 'Augsburg',
    'About12Country6Town3': 'Berlin',
    'About12Country6Town4': 'Bochum',
    'About12Country6Town5': 'Bonn',
    'About12Country6Town6': 'Cologne',
    'About12Country6Town7': 'Dortmund',
    'About12Country6Town8': 'Dusseldorf',
    'About12Country6Town9': 'Essen',
    'About12Country6Town10': 'Frankfurt',
    'About12Country6Town11': 'Hamburg',
    'About12Country6Town12': 'Hanover',
    'About12Country6Town13': 'Karlsruhe',
    'About12Country6Town14': 'Leipzig',
    'About12Country6Town15': 'Lübeck',
    'About12Country6Town16': 'Mainz/Wiesbaden',
    'About12Country6Town17': 'Muenster',
    'About12Country6Town18': 'Munich',
    'About12Country6Town19': 'Nuremberg',
    'About12Country6Town20': 'Pforzheim',
    'About12Country6Town21': 'Rostock',
    'About12Country6Town22': 'Stuttgart',
    'About12Country7': 'Italy',
    'About12Country7Town1': 'Milan',
    'About12Country7Town2': 'Modena',
    'About12Country7Town3': 'Reggio Emilia',
    'About12Country7Town4': 'Rho',
    'About12Country7Town5': 'Turin',
    'About12Country8': 'Norway',
    'About12Country8Town1': 'Bergen',
    'About12Country8Town2': 'Fredrikstad',
    'About12Country8Town3': 'Kristiansand',
    'About12Country8Town4': 'Lillestrøm',
    'About12Country8Town5': 'Oslo',
    'About12Country8Town6': 'Stavanger',
    'About12Country8Town7': 'Trondheim',
    'About12Country9': 'Spain',
    'About12Country9Town1': 'Malaga',
    'About12Country9Town2': 'Seville',
    'About12Country10': 'Sweden',
    'About12Country10Town1': 'Borås',
    'About12Country10Town2': 'Eskilstuna',
    'About12Country10Town3': 'Gothenburg',
    'About12Country10Town4': 'Halmstad',
    'About12Country10Town5': 'Helsingborg',
    'About12Country10Town6': 'Linköping',
    'About12Country10Town7': 'Lund',
    'About12Country10Town8': 'Malmö',
    'About12Country10Town9': 'Norrköping',
    'About12Country10Town10': 'Orebro',
    'About12Country10Town11': 'Stockholm',
    'About12Country10Town12': 'Uppsala',
    'About12Country10Town13': 'Vasteras',
    'About12Country11': 'Switzerland',
    'About12Country11Town1': 'Basel',
    'About12Country11Town2': 'Bern',
    'About12Country11Town3': 'Frauenfeld',
    'About12Country11Town4': 'Illnau-Effretikon',
    'About12Country11Town5': 'Winterthur',
    'About12Country11Town6': 'Zurich',
    'About12Country12': 'UK',
    'About12Country12Town1': 'Cambridge',
    'About12Country12Town2': 'Cheltenham',
    'About12Country12Town3': 'Corby',
    'About12Country12Town4': 'Gloucester',
    'About12Country12Town5': 'Kettering',
    'About12Country12Town6': 'Liverpool',
    'About12Country12Town7': 'London',
    'About12Country12Town8': 'Northampton',
    'About12Country12Town9': 'Oxford',
    'About12Country12Town10': 'Portsmouth',
    'About12Country12Town11': 'Rushden\n' +
        '            &amp; Higham Ferrers',
    'About12Country12Town12': 'Southampton',
    'About12Country12Town13': 'Wellingborough',
    'About13Title': 'Leadership',
    'About13Text1': 'Get to know a few members of Voi Technology\'s leadership team, responsible for setting the course for our company direction, along with some regional leaders putting it into practice across Europe.',
    'About14Title': 'Our journey so far',
    'About14Text1': '2018',
    'About14Text2': 'Voi was founded in Stockholm by Fredrik Hjelm, Adam Jafer, Filip Lindvall, and Douglas Stark as Europe\'s pioneering e-scooter operator. Since day one, our Swedish heritage has driven our commitment to traffic safety, great parking solutions, operational efficiency, and providing an affordable, accessible mode of transportation for all.',
    'About14Text3': '2019',
    'About14Text4': 'Voi launched e-scooters in 40+ European towns and cities and reached 1 million rides across all markets. To improve rider safety, we created RideSafe Academy (previously called RideLikeVoila) - the first-ever online traffic school for electric scooters. To further improve our operational excellence, we introduced swappable batteries, reducing energy consumption and emissions.',
    'About14Text5': '2020',
    'About14Text6': 'In 2020, Voi won a number of ground-breaking e-scooter tenders in several European cities, including exclusive licenses in the majority of the towns & cities participating in the UK\'s e-scooter trial initiative – solidifying our position as a trusted and reliable partner for cities.',
    'About14Text7': 'We also introduced several in-app safety features, such as a low-speed mode, a reaction test for night-time riding, and the Voi Pass - a subscription-based pricing model making it more affordable to use Voi as an everyday transportation method.',
    'About14Text8': '2021',
    'About14Text9': 'Voi expanded its mobility offerings by launching its first e-bikes. To continue our innovation, a new generation of e-scooters was introduced, the Voiager 4, featuring turn indicators to increase safety, and improved geo-targeting for more precise parking solutions.',
    'About14Text10': 'Behind the scenes, we continued improving our cutting-edge operations technology through real-time demand prediction and fleet optimization software powered by machine learning. This ensures our vehicles are utilised to the fullest extent possible and provides riders with the best availability, key factors behind our operational excellence.',
    'About14Text11': '2022',
    'About14Text12': 'In 2022, Voi reached a significant milestone of 100 million rides and the launch of our 100th city. We also unveiled our next-generation vehicle, the Voiager 5, our safest and most durable e-scooter to date. Featuring a highly modular design that simplifies maintenance and facilitates the reuse of parts, the Voiager 5 is built to last, with an extended vehicle lifespan of up to five years.',
    'About14Text13': 'We continued scaling our “Voi Parking Assistant” product feature across Europe, supporting users to park correctly and rewarding good parking, whilst taking punitive measures for recurring poor parking. Additionally, Voi was able to win re-tenders, for example in France and Denmark, showcasing the trust retendered cities have in Voi.',
    'About15Title': 'We\'re only at the start of our voyage',
    'About15Text1': 'Follow our blog for the latest news and insights from Voi Technology.',
    'About15Btn': 'Read our blog',
    'CitiesOneTitle1': 'Your city, our',
    'CitiesOneTitle2': 'promise',
    'CitiesOneText1': 'Voi works hand in hand with city officials and organisations to solve city pain points, providing customised solutions that address your area\'s specific transportation needs and challenges.',
    'CitiesTwoTitle': 'Safe, sustainable and reliable micromobility for everyone',
    'CitiesTwoText1': 'We offer different modes of light mobility vehicles that are safe, affordable, and easy to use – allowing people to move around the city on their terms.',
    'CitiesTwoText2': 'By reducing reliance on cars and improving access to sustainable mobility, we can strengthen public transport networks to help create better, safer and more livable cities for everyone.',
    'CitiesThreeTitle': 'Addressing safety and parking challenges',
    'CitiesThreeText1': 'At Voi, we are committed to safety, inspired by our Swedish heritage and the Vision Zero approach. This means prioritising the safety of all road users, not just our riders. We utilise advanced technology and provide a wide range of features that increase safety.',
    'CitiesThreeText2': 'For instance, our technology allows us to prevent pavement and intoxicated riding, and limit vehicle speed in designated areas. More so, our industry-leading parking solutions tackle one of the key safety concerns for pedestrians and vulnerable road users: obstructed pathways.',
    'CitiesFourTitle': 'No town or city is completely alike',
    'CitiesFourText1': 'We know that each city has its unique challenges and opportunities, and we are committed to providing customised safety solutions to address them. With the largest fleet of licensed and tendered e-scooters across Europe, we know how to address safety and parking concerns.',
    'CitiesFourText2': 'For example, in some areas, we collaborate with the city to install physical racks and painted bays that effectively solve parking challenges. In other areas, we use geofencing technology to prevent parking at certain locations. We also offer a "Parking Assistant" feature that rewards users for parking correctly and takes punitive measures for recurring poor parking.',
    'CitiesFourText3': 'Our goal is to work with each city to create tailored solutions that address the specific safety challenges in their area. We believe that our city-first approach is the key to creating safe and sustainable transportation options for everyone.',
    'CitiesFourBtn': 'Our approach',
    'CitiesFiveTitle': 'Solving the first and last-mile problem',
    'CitiesFiveText1': 'Voi offers an efficient way to solve the first and last-mile problem and improve access to public transport in cities. As an example, 55% of our riders typically combine their e-scooter trip with public transport today (per our survey).',
    'CitiesFiveText2': 'Our ready-to-use framework uses standard application programming interfaces (APIs) for shared mobility operators, such as GBFS & MDS, making our service easily integrated with public transportation.',
    'CitiesSixTitle': 'Leveraging data to improve transit routes',
    'CitiesSixText1': 'By collecting complex data from our service and presenting it in an accessible way, we can help city officials obtain a comprehensive understanding of shared mobility.',
    'CitiesSixText2': 'For example, we can provide heat maps that can be used to analyse the busiest areas of a city, which can then be used to optimise transit routes and identify locations that require additional coverage.',
    'CitiesSevenTitle': 'The resources needed to succeed',
    'CitiesSevenText1': 'Access to data is crucial for cities to succeed with shared micromobility. We use established data-sharing practices and customised dashboards that offer real-time information on demand, usage patterns, compliance with regulations, and more.',
    'CitiesSevenText2': 'This transparency and our dedicated local teams who work diligently to maintain and manage our fleet and ground operations ensure the high quality and control of our service.',
    'CitiesEightTitle': 'By offering alternative modes of transportation and enhancing access to public transit, micromobility can help cities reach their climate goals.',
    'SafeOneTitle': 'Our commitment to safety',
    'SafeOneText': 'Safety has been at the core of what we do since the start, and it is an essential part of our vision of creating cities made for living. Voi takes a data-driven, and research-led approach to building the safest micromobility service on the streets, and our safety measures make a difference.',
    'SafeTwoTitle': 'Promoting safety transparency',
    'SafeTwoText1': 'Our Safety Report serves a dual purpose: not only does it promote accountability, but it also enables us to leverage our insights to mitigate accidents and create safer public spaces for all.',
    'SafeTwoText2': 'By increasing transparency about our safety initiatives and highlighting the steps we are taking to achieve our Vision Zero objective, we hope to inspire confidence in our efforts to improve safety.',
    'SafeTwoBtn': 'Download the report',
    'SafeThreeTitle': 'Voi’s Vision Zero approach focuses on three crucial areas',
    'SafeThreeSTitle1': 'Education',
    'SafeThreeText1': 'Promoting safe rider behaviour and using advanced technology to protect pedestrians',
    'SafeThreeSTitle2': 'Hardware',
    'SafeThreeText2': 'Building the safest vehicles and taking proactive measures to prevent accidents.',
    'SafeThreeSTitle3': 'Collaboration',
    'SafeThreeText3': 'Collaborating with cities to improve infrastructure and create safer streets.',
    'SafeFourTitle': 'Promoting safe rider behaviour',
    'SafeFourText1': 'Empowering our users with the knowledge and skills to ride safely is fundamental.',
    'SafeFourText2': 'To achieve this, we continuously provide education on traffic rules and safe ride training through various means, such as our online learning platform RideSafe Academy, digital education and in-person safety events.',
    'SafeFiveTitle': 'Protecting pedestrians',
    'SafeFiveText1': 'We utilise advanced technology to ensure the safety of pedestrians and other vulnerable road users, providing a range of features that increase safety.',
    'SafeFiveText2': 'For instance, our technology allows us to prevent pavement and intoxicated riding, and limit vehicle speed in designated areas. Moreover, our industry-leading parking solutions tackle one of the key safety concerns for pedestrians and vulnerable road users: obstructed pathways.',
    'SafeSixTitle': 'No town or city is completely alike',
    'SafeSixText1': 'At Voi, we know that each city has its unique challenges and opportunities, and we are committed to providing customised safety solutions to address them. With the largest fleet of licensed and tendered e-scooters across Europe, we know how to address safety and parking concerns.',
    'SafeSixText2': 'For example, in some areas, we collaborate with the city to install physical racks and painted bays that effectively solve parking challenges. In other areas, we use geofencing technology to prevent parking at certain locations. We also offer a "Parking Assistant" feature that rewards users for parking correctly and takes punitive measures for recurring poor parking.。',
    'SafeSixText3': 'Our goal is to work with each city to create tailored solutions that address the specific safety challenges in their area. We believe that this approach is the key to creating safe and sustainable transportation options for everyone.',
    'SafeSevenTitle': 'Designing a safer ride',
    'SafeSevenText1': 'Each new generation of vehicles comes equipped with various safety improvements to address the unique challenges and needs of the shared micromobility industry.',
    'SafeSevenText2': 'For example, as a direct response to rider and city preferences, we have implemented several safety features such as double-handed brakes, turn indicators and larger wheel sizes.',
    'SafeSevenBtn': 'Our vehicles',
    'SafeEightTitle': 'Ensuring that vehicles are safe at all times',
    'SafeEightText1': 'Our vehicles are equipped with multiple unique sensors, continuously monitoring the condition of our fleet and taking proactive measures to prevent accidents. We conduct regular inspections, including in-field quality checks and on-site repairs to minimise transportation to our warehouses.',
    'SafeEightText2': 'Our sensors are also able to detect if one of our vehicles has fallen over. This information, along with feedback from our report tool "Report a Voi," goes directly to our local in-field team, allowing us to quickly take action to ensure the safety of everyone on the streets.',
    'SafeNineTitle': 'Guiding cities to improve infrastructure',
    'SafeNineText1': 'Infrastructure is one of the most crucial factors in ensuring the safety of micromobility. Using data from our vehicles and feedback from riders, we can help cities make improvements to urban infrastructure based on the real-time needs of their citizens by identifying accident hotspots and areas with degraded road conditions.',
    'ParkHeaderTitle': 'Parking solutions',
    'ParkHeaderText': 'Micromobility has emerged as a proven and effective solution to alleviate traffic congestion on roads. However, it\'s crucial to ensure responsible parking practices to prevent congestion in other areas.',
    'ParkOneTitle': 'The importance of proper parking',
    'ParkOneText1': 'As a responsible micromobility provider, we recognize that Voi vehicles occupy city space and it\'s our duty to ensure that our users park them in a responsible and appropriate manner.',
    'ParkOneText2': 'Parking compliance is an essential part of our safety commitment and plays a key role in keeping both riders and pedestrians safe – particularly for vulnerable groups including those with mobility issues and the partially sighted.',
    'ParkTwoTitle': 'Our solutions are proven to work',
    'ParkTwoText1': 'Our approach to solving parking challenges is unique in the industry, and our solutions have been proven to work.',
    'ParkTwoText2': 'For instance, a study conducted by the Norwegian Institute of Transport Economics on parking solutions for shared e-scooters serves as testimony to this.',
    'ParkTwoBtn': 'Read the study',
    'ParkThreeTitle': 'No town or city is completely alike',
    'ParkThreeText1': 'We know that each city has its unique challenges and opportunities, therefore, we provide customised solutions to address them. Through a combination of high-end technological solutions, high-precision GPS, efficient operations, and other targeted initiatives, we are able to ensure orderly, safe and legal parking.',
    'ParkFourTitle': 'Discover some of our cutting-edge parking solutions',
    'ParkFiveTitle': 'Dedicated parking spots',
    'ParkFiveText1': 'Allocating dedicated parking spots for shared e-scooters is a highly effective solution. By setting up established no-parking zones within e-scooter apps and using software designed to prevent riders from ending a journey outside of a safe parking spot, it is possible to encourage riders to seek out such solutions.',
    'ParkFiveText2': '\n' +
        'We collaborate with cities to carefully plan the provision and locations of parking zones within operational areas. The optimal parking design varies significantly across cities, depending on factors such as the operating zone and city density and available infrastructure.',
    'ParkFiveText3': 'However, certain aspects remain consistent, including:',
    'ParkFiveText4': 'When deploying mandatory parking spots, such as in dense city centres, the parking spot should be physically identifiable.',
    'ParkFiveText5': 'Mandatory parking spots should have a minimum density of 30 per km² to ensure sufficient availability.',
    'ParkFiveText6': 'The proportion of available parking spaces per vehicle should be at least 3:1 to ensure a smooth flow.',
    'ParkFiveText7': 'Read more in our',
    'ParkFiveText8': 'shared industry recommendations',
    'ParkFiveText9': 'to learn more information',
    'ParkSixTitle': 'Technology to the benefit of all',
    'ParkSixText1': 'Our vehicles are equipped with multiple advanced sensors, which, when used in conjunction with state-of-the-art computer vision and AI technology, enable us to automatically generate alerts to Voi\'s local operations team in the event of an incorrectly parked vehicle.',
    'ParkSixText2': 'By leveraging this technology and data, we can quickly rectify any parking issues and stay ahead of potential problems.',
    'ParkSevenTitle': 'Helping users park',
    'ParkSevenText1': 'Incentivized parking encourages better parking by offering discounts for parking in designated areas. Moreover, our \'Parking Assistant\' helps users to park correctly and rewards good parking, whilst taking punitive measures for recurring poor parking.',
    'ParkSevenText2': 'At the end of each trip, riders must submit a parking photo, reinforcing the importance of proper parking. This feature also allows for feedback to riders in the event of non-compliant parking, ensuring a prompt and efficient resolution to any issues.',
    'ParkEightTitle': 'A collective effort',
    'ParkEightText1': 'Although poor parking behaviour is limited to a small group of riders, a single improperly parked vehicle can hinder accessibility. Our top priority is to develop best-in-class parking solutions, alongside other safety efforts. Our experience has demonstrated that close collaboration between operators and e-scooter providers is crucial to achieving this goal.',
    'MobilityHeaderTitle': 'Mobility Partners',
    'MobilityHeaderText1': 'Together, let’s make the mobility of tomorrow happen.',
    'MobilityOneTitle': 'Our mobility partners are essential to achieve our vision of cities made for living, free from noise and pollution',
    'MobilityOneText1': 'From day one, our Swedish heritage has fueled our dedication to making affordable, accessible and sustainable transportation available to all. We collaborate with numerous public transport authorities and mobility providers all over Europe, making it possible for all citizens to have access to a large variety of such mobility services.',
    'MobilityOneText2': 'Our integration solutions provide the technology that can bind this together, enabling citizens to choose the mode of transport or a combination of different modes to fulfill their transport needs, thus creating tailored solutions for every trip. As such, Voi and its partners increase the flexibility of choice, nudging towards greener ways to travel, and transforming the way society moves around.',
    'MobilityTwoTitle': 'Voi’s partner solutions',
    'MobilityTwoText1': 'Our ready-to-use framework uses standard APIs for shared mobility operators, making it easy for any partner and platform to integrate with our service.',
    'MobilityTwoTitle1': 'Light integrations',
    'MobilityTwoText2': 'Our light integrations enable the sharing of real-time information about our vehicles, such as location, availability, pricing, and geofenced areas for rules related to speed, parking, and prohibited zones. A light integration will enable users to find available shared micromobility options near them. Booking vehicles, payment and customer service will in turn be handled by Voi.',
    'MobilityTwoText3': 'Light integrations are achieved using GBFS (General Bikesharing Feed Specification) and MDS (Mobility Data Specification) depending on the partner’s needs.',
    'MobilityTwoTitle2': 'Deep integrations',
    'MobilityTwoText4': 'Our deep integrations allow partners to deliver the full end-to-end Voi user experience through their platform. By providing support for booking and the payment flows for all modes used during the trip -such as buses, trains and Voi- through one single interface, the convenience of multimodality involving public transport increases.',
    'MobilityTwoText5': 'For deep integrations, Voi has developed a standard MaaS (Micromobility as a Service) framework, making it easy to integrate the full Voi service.',
    'MobilityTwoTitle3': 'Physical integrations: Mobility hubs',
    'MobilityTwoText6': 'In collaboration with public transportation partners and cities, we also support the creation of mobility hubs. Mobility hubs offer people the opportunity to leave their cars at home and enjoy a seamless trip using a combination of different transport modes while improving order and convenience around transport hubs.',
    'MobilityTwoText7': 'For instance, Voi can provide dedicated parking spots, physical parking infrastructure and analysis of usage patterns.',
    'MobilityThreeTitle': 'Case Study: Integrating Voi with Public Transport in Stuttgart',
    'MobilityThreeText1': 'A pilot initiative in Stuttgart demonstrated that integrating shared micromobility into the local public transport system can help increase public transport ridership. Voi was part of the project, along with the local rail operator S-Bahn Stuttgart and the MaaS platform provider Mobimeo.',
    'MobilityThreeText2': 'Voi\'s e-scooter service became available for booking through the Mobility Stuttgart App, connecting shared mobility to public transport with a traffic planner and making it easier for users to plan their trips. Furthermore, users were offered vouchers when combining different modes of transportation, and parking racks for e-scooters were placed at the train station.',
    'MobilityThreeText3': 'Research showed an increase in rides with shared e-scooters by 250%, which contributed to a 35% increase in train ticket sales during the pilot period. The initiative also found that physical parking infrastructure at the train station can reduce clutter from e-scooters.',
    'MobilityThreeBtn': 'Read the case study',
    'MobilityFourTitle': 'Interested in collaborating with Voi?',
    'MobilityFourText1': 'Are you interested in finding out more about Voi’s integrated micromobility solutions or want to discuss an idea? Get in touch with us to start the conversation or access our partner documentation.',
    'MobilityFourBtn': 'Contact us',
    'MobilityFiveTitle': 'Partners',
    'MobilitySixTitle': 'Together, let’s create a more sustainable and efficient transport system in cities.',
    'SustainabilityHeaderTitle': 'Sustainability',
    'SustainabilityHeaderText': 'Our commitment towards the climate and environment is fundamental to the entire business.',
    'SustainabilityOneTitle': 'Cities made for living, free from noise and pollution',
    'SustainabilityOneText1': 'Micromobility has the potential to help decarbonise urban transportation by providing alternatives to cars and improving access to public transportation.',
    'SustainabilityOneText2': 'We take a holistic approach to address our environmental footprint and strive to always improve our sustainability practices.',
    'SustainabilityTwoTitle': 'Important steps so far',
    'SustainabilityTwoTitle1': 'Enhanced access to mobility',
    'SustainabilityTwoText1': 'Shared micromobility provides a convenient complement to the public transport network, enhancing citizens\' access to sustainable transportation. For example, 55% of riders typically combine their e-scooters trip with public transport, per our user survey.',
    'SustainabilityTwoTitle2': 'New battery technology',
    'SustainabilityTwoText2': 'Swappable batteries have led to significant reductions in emissions, energy consumption and contribution to traffic congestion. Batteries are swapped directly on the street, and only the used batteries must be transported for charging.',
    'SustainabilityTwoTitle3': 'Circular vehicle program',
    'SustainabilityTwoText3': 'Through our circular vehicle program, we have managed to increase the operational lifespan of our fleet from less than one year back in 2018, to an average five-year lifespan for our V5 model in 2022, verified by a third party.',
    'SustainabilityThreeTitle': 'We continuously work to decrease emissions and aim to be climate-positive by 2030.',
    'SustainabilityFourTitle': 'Measuring emissions',
    'SustainabilityFourText': 'As a company committed to accountability and transparency, we were the first e-scooter operator to publish a comprehensive Life Cycle Assessment of our service. Our LCA allows us to identify the materials, production, and end-of-life processes that contribute to emissions and to focus our emission reduction efforts accordingly.',
    'SustainabilityFiveTitle': 'Reducing emissions',
    'SustainabilityFiveText': 'Our Environmental Action Plan targets emission reduction initiatives and focuses on extending the lifespan of our vehicles, transitioning to zero-emission operations, and implementing circular practices.',
    'SustainabilitySixTitle': 'We are working to make our service fully circular with zero waste by 2025.',
    'SustainabilitySevenTitle': 'Rethink',
    'SustainabilitySevenText': 'Shared vehicles can be more efficiently used during their lifetime, enabling a resource-efficient transition to low-carbon mobility. We continue to innovate and develop our service to increase access to shared mobility.',
    'SustainabilityEightTitle': 'Reinvent',
    'SustainabilityEightText': 'We have in-house R&D for vehicle development, working closely with our suppliers to reinvent vehicle design and minimise resource use. We have extended the lifespan of our scooters to five years, and improved battery lifespan thanks to smarter charging.',
    'SustainabilityNineTitle': 'Repair',
    'SustainabilityNineText': 'Maintenance and repairing is an essential part of extending our vehicles\' lifespan. We work with employed and trained staff who have contributed to improving our retention rate with spare parts sourced in Europe.',
    'SustainabilityTenTitle': 'Reuse',
    'SustainabilityTenText': 'We reuse components and spare parts as much as possible. For instance, nearly all of our spare parts are reused to repair other vehicles or parking racks. Plastic and rubber are reused for tactile strips for our parking racks and corrals.',
    'Sustainability11Title': 'Repurpose',
    'Sustainability11Text': 'We refurbish our older vehicles and re-sell them to other companies to extend their lifespan. We also work with partners to give batteries a second life for energy storage.',
    'Sustainability12Title': 'Recycle',
    'Sustainability12Text': 'When no repurposing or reuse within Voi’s value chain is possible, materials are recycled with support from our recycling partners. All our waste is recycled through our local recycling partners, and we work towards a 0% landfill rate.',
    'Sustainability13Title': 'Sustainability network',
    'Sustainability14Title': 'Our climate and social responsibility are not limited to us',
    'Sustainability14Text': 'We work with suppliers and partners who prioritise responsible practices.',
    'Sustainability14Btn': 'Our Code of Conduct',
    'ModuleNewsTitle': 'Latest news',
    'ModuleNewsText1': 'Important updates on regulations in Malmö: What Voi riders need to know',
    'ModuleNewsText2': 'Voi publishes annual Safety Report and commits to spearhead safety reporting towards Vision Zero',
    'ModuleNewsText3': 'Save pounds and pennies with Voi in the UK',
    'TermsText1': 'USER AGREEMENT AND TERMS AND CONDITIONS FOR USING THE VOI SERVICES',
    'TermsText2': 'This agreement (the “',
    'TermsText3': 'Agreement',
    'TermsText4': '”) sets forth the legally binding terms and conditions\n' +
        '        for Your use of the Services provided by Voi Technology UK Limited, a company registered in England with company\n' +
        '        number 12616585 and with its registered office at c/o GBP Knox, 2nd Floor, National House, 60-66 Wardour Street,\n' +
        '        London, W1F 0TA, United Kingdom (“',
    'TermsText5': 'Voi',
    'TermsText6': 'or',
    'TermsText7': 'We',
    'TermsText8': '”). The purpose of this Agreement is to regulate\n' +
        '        the Services provided by Voi, which is to offer you (“',
    'TermsText9': 'You',
    'TermsText10': '”) the use of electrical scooters and/or e-bikes\n' +
        '        after You have registered Yourself in the Voi App.',
    'TermsText11': 'DEFINITIONS',
    'TermsText12': 'In this Agreement, the following words, terms and phrases have the following meaning:',
    'TermsText13': 'E-bikes',
    'TermsText14': 'means Voi’s shared electric bikes.',
    'TermsText15': 'E-scooters',
    'TermsText16': 'means Voi’s shared electric scooters.',
    'TermsText17': 'Incentivised Parking Zone',
    'TermsText18': 'means the areas defined and\n' +
        '          designated in the Voi App in which parking is incentivised (e.g. by way of Voi Credits).',
    'TermsText19': 'Long Term Rental',
    'TermsText20': 'has the meaning given to it in section 1.11.',
    'TermsText21': 'Mandatory Parking Zone',
    'TermsText22': 'means the areas defined and\n' +
        '          designated in the Voi App in which You must temporarily park and deactivate the Vehicle.',
    'TermsText23': 'Minimum Rental Period',
    'TermsText24': 'means a minimum 60-day initial period when You agree to a Long Term Rental.',
    'TermsText25': 'Non-Parking Zone',
    'TermsText26': 'means the areas defined and designated\n' +
        '          in the Voi App in which the Vehicles may not be deactivated. As You may not be able to reactivate a Vehicle\n' +
        '          once it has been deactivated in such an area You should by all means avoid such an area because failure to\n' +
        '          comply may result in additional costs.',
    'TermsText27': 'Operational Zone',
    'TermsText28': 'means the areas defined and designated\n' +
        '          in the Voi App in which the Vehicles may be activated, used, temporarily parked and deactivated.',
    'TermsText29': 'Privacy Policy',
    'TermsText30': 'means the privacy policy regarding Voi’s processing of personal data made available by Voi at',
    'TermsText31': 'Vehicles',
    'TermsText32': 'is the collective term for Voi’s e-scooters and e-bikes.',
    'TermsText33': 'Services',
    'TermsText34': 'means the use of the Vehicles, Website, Voi App\n' +
        '          and its related services as described on the Website and in the Voi App, and made available through the\n' +
        '          Website or the Voi App, together with any other related equipment services, and information made available by\n' +
        '          Voi.',
    'TermsText35': 'Voi App',
    'TermsText36': 'means Voi proprietary software applications\n' +
        '          accessible via computer or mobile device and by which You may access the Vehicle.',
    'TermsText37': 'Voi Credits',
    'TermsText38': 'has the meaning given to it in section 2.7.',
    'TermsText39': 'Voi Pass',
    'TermsText40': 'means the right to use the Services by way of either a\n' +
        '            subscription-based purchase (e.g. monthly pass) or purchase of a fixed use period (e.g. 24-hour pass), as\n' +
        '            further described in the Voi App. Voi Pass may be available in different versions:',
    'TermsText41': 'Free Unlock Pass',
}